import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Box from "../components/box";
import Img from "../components/img";
import { Title, Subtitle, SmallTitle, BodyText } from "../components/text";

import useEntryAnimation from "../utility/useEntryAnimation";

const IndexPage = () => {
  const container = useEntryAnimation();

  return (
    <Layout>
      <SEO title="Applications" />

      <div ref={container}>
        <Box my={5} mt={6}>
          <SmallTitle data-reveal>Applications</SmallTitle>

          <Title data-reveal="lines" fontSize={6}>
            Lorem ipsum dolor sit amet consecutor adipiscing elit
          </Title>
        </Box>

        <Box data-reveal my={5}>
          <Box my={4}>
            <Link to="/applications/mining">
              <Img
                width={"100%"}
                mb={1}
                src="https://picsum.photos/400/500?random"
              />
              <Subtitle my={2} fontSize={2}>
                Mining
              </Subtitle>

              <BodyText color="mute">
                The only thing we love more than being put to the test is being
                put straight to work on large scale projects.
              </BodyText>
            </Link>
          </Box>

          <Box my={4}>
            <Link to="/applications/mining">
              <Img
                width={"100%"}
                mb={1}
                src="https://picsum.photos/400/500?random&a"
              />
              <Subtitle my={2} fontSize={2}>
                Things
              </Subtitle>
              <BodyText color="mute">
                The only thing we love more than being put to the test is being
                put straight to work on large scale projects.
              </BodyText>
            </Link>
          </Box>

          <Box my={4}>
            <Link to="/applications/lorem">
              <Img
                width={"100%"}
                mb={1}
                src="https://picsum.photos/400/500?random&b"
              />
              <Subtitle my={2} fontSize={2}>
                Construction
              </Subtitle>
              <BodyText color="mute">
                The only thing we love more than being put to the test is being
                put straight to work on large scale projects.
              </BodyText>
            </Link>
          </Box>

          <Box my={4}>
            <Link to="/applications/lorem">
              <Img
                width={"100%"}
                mb={1}
                src="https://picsum.photos/400/500?random&c"
              />
              <Subtitle my={2} fontSize={2}>
                Solar Power
              </Subtitle>
              <BodyText color="mute">
                The only thing we love more than being put to the test is being
                put straight to work on large scale projects.
              </BodyText>
            </Link>
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

export default IndexPage;
